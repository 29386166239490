globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"c5535ba3bb59140b8c05b4a711d5c3ac48dd3d49"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

// TODO: Remove this workaround - use NEXT_PUBLIC variables when they will be available in all environment.
const publicEnvironmentVariables = window.PUBLIC_ENV;

Sentry.init({
  debug: false,
  dsn: publicEnvironmentVariables?.sentryDSN,
  enabled: publicEnvironmentVariables?.appEnvironment !== 'LOCAL',
  environment: publicEnvironmentVariables?.appEnvironment,
  ignoreErrors: [
    "Hydration failed - the server rendered HTML didn't match the client.",
    "Failed to execute 'getComputedStyle' on 'Window'",
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.05,
  tracesSampleRate: 0.05,
});
